<template>
  <b-overlay :show="loading">
    <b-card header="Aggiungi Opera">
      <b-card-body>

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="12">

                <cs-validation
                  label="Codice Opera Globale"
                  rules="required|length:14"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="codiceOperaGlobale"
                      v-model="model.opera.codiceOperaGlobale"
                      label="Codice Opera Globale"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">

                <cs-input
                  id="codiceIop"
                  v-model="model.opera.codiceIop"
                  label="Codice IOP"
                />

              </b-col>
              <b-col cols="6">

                <cs-validation
                  label="Numero"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="numero"
                      v-model="model.opera.numero"
                      type="number"
                      label="Numero"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">

                <cs-validation
                  label="Nome"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="nome"
                      v-model="model.opera.nome"
                      label="Nome"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>
                </cs-validation>

              </b-col>
              <b-col cols="6">

                <cs-input
                  id="codiceCup"
                  v-model="model.opera.codiceCup"
                  label="Codice CUP"
                />

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="anagrafica.tipologia"
                  v-model="model.opera.tipologia"
                  label="Tipologia Opera"
                  :reduce="r => r.id"
                  :options="options"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">

                <cs-input
                  id="note"
                  v-model="model.opera.note"
                  label="Note"
                />

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-validation
                  label="Csv"
                  rules="required"
                >
                  <template slot-scope="props">

                    <b-form-file
                      v-model="model.excel"
                      accept=".csv"
                      placeholder="Seleziona o trascina un csv qui..."
                      drop-placeholder="Trascina un csv qui..."
                      :state="props.errors.length > 0 ? false : null"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
      <b-card-footer>
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      model: {
        opera: {
          codiceOperaGlobale: null,
          codiceIop: null,
          numero: null,
          codiceCup: null,
          nome: null,
          note: null,
          tipologia: null,
        },
        excel: null,
      },
      options: [
        { id: 1, label: 'PONTE' },
        { id: 2, label: 'VIADOTTO' },
        { id: 3, label: 'SOTTOVIA' },
        { id: 4, label: 'CAVALCAVIA' },
        { id: 5, label: 'SOVRAPPASSO' },
        { id: 6, label: 'SOPRAELEVATA' },
        { id: 7, label: 'GALLERIA' },
        { id: 8, label: 'TOMBINO' },
        { id: 9, label: 'CORPO STRADALE' },
        { id: 10, label: 'CORPO DI FABBRICA' },
        { id: 11, label: 'STAZIONE' },
        { id: 12, label: 'FERROVIA' },
        { id: 14, label: 'SOTTOPASSO' },
        { id: 13, label: 'ALTRA TIPOLOGIA OPERA' },
      ],
    }
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.opere.create(this.model)
            .then(() => {
              // const canView = this.$grants.OPERE_DETTAGLIO.can
              // if (canView) this.$routing.OPERE_DETTAGLIO.push(res.data)
              // else
              this.showAddSuccessMessage()
            })
            .catch(res => {
              this.showReposonseMessage(res)
            })
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
